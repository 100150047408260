import {ListGroup, Stack} from "react-bootstrap";
import {Paths} from "../enums/Paths";
import React from "react";
import FavouriteButton from "./FavouriteButton";

/**
 * @typedef {"DEFAULT", "NEAREST"} StationListElementVariants Warianty listy stacji
 */

/**
 * Konwertuje odległość do najbliższej stacji (w metrach) na ciąg znakowy
 * @param {number} distance
 * @returns string
 */
function distanceToString(distance) {
    if (distance < 1000)
        return `${distance} m`;
    distance = Math.round(distance / 100) / 10;
    return `${distance} km`;
}

/**
 * Element listy stacji
 * @param {stationsAPI|stationsDistance} station
 * @param {function(string)} navigate
 * @param {StationListElementVariants} variant
 * @returns {JSX.Element}
 */
export default function StationListElement({station, navigate, variant = "DEFAULT"}) {
    let stationText = "";
    switch (variant) {
        case "NEAREST":
            stationText = `${station.stop_name} (${distanceToString(station.distance)})`;
            break;
        case "DEFAULT":
        default:
            stationText = station.stop_name;
    }

    return <ListGroup.Item
        className={"d-flex list-group-item-action"}
    >
        <Stack className={"w-100 pointer"} direction={"horizontal"}>
            <div className={"w-100"} onClick={() => navigate(Paths.STATION(station.stop_id))}>
                {stationText}
            </div>
            <div>
                <FavouriteButton idStation={station.stop_id}/>
            </div>
        </Stack>
    </ListGroup.Item>
}
